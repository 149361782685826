import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
// import 'react-select2-wrapper/css/select2.css'
import Pagination from 'react-js-pagination'
import Loader from '../Layout/common/Loader'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import RangeSlider from 'react-bootstrap-range-slider'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import { useEffect } from 'react'
import { ContentMain } from '../../services/main-content'
import { useSelector, useDispatch } from 'react-redux'
import {
	fetchallproducts,
	sortProductsByAscPrice,
	sortProductsByAscWeight,
	sortProductsByDescPrice,
	sortProductsByDescWeight,
} from '../../store/actions/products.action'
import { add_to_cart } from '../../store/actions/cart.action'
import {
	setloadingfalse,
	setloadingtrue,
} from '../../store/actions/global.action'
import { incrementData } from '../../store/actions/products.action'
import { toast } from 'react-toastify'
import {
	applyFilter,
	clearFilter,
	clearFilterDOMValues,
	saveFilterDOMValues,
} from '../../store/actions/filter.action'

const MainContent = props => {
	let listItems = []

	const [filters, setFilters] = useState({
		stone_type_id: null,
		diamond_type_id: null,
		parcel_type_id: null,
		shape_id: null,
		stone_cut_id: null,
		color_id: null,
		origin_id: null,
		treatment_id: null,
		size_start_from: 0,
		size_start_to: 0,
		size_end_from: 0,
		size_end_to: 0,
		reference_name: null,
		stone_length_from: null,
		stone_width_from: null,
		stone_height_from: null,
		stone_length_to: null,
		stone_width_to: null,
		stone_height_to: null,
		weight_range_from: null,
		weight_range_to: null,
		price_range_from: 0,
		price_range_to: 0,
	})
	const [maxPrice, setMaxPrice] = useState(0)
	const [minPrice, setMinPrice] = useState(0)
	const [parcelTypes, setParcelTypes] = useState(null)
	const [gemStoneTypes, setGemstoneTypes] = useState(null)
	const [getStoneCuts, setGemStoneCuts] = useState(null)
	const [stone_type_color, setStone_type_color] = useState(null)
	const [stone_type_origin, setStone_type_origin] = useState(null)
	const [stone_type_shape, setStone_type_shape] = useState(null)
	const [stone_type_treatment, setStone_type_treatment] = useState(null)
	// const [loading,setLoading]=useState(false)
	const [filters_change, setFilterchange] = useState(false)
	const [allOptions, setAllOptions] = useState([])
	const [diamondTypeOptions, setDiamondTypeOptions] = useState([])
	const [colorOptions, setColorOptions] = useState([])
	const [shapeOptions, setShapeOptions] = useState([])
	const [originOptions, setOriginOptions] = useState([])
	const [treatmentOptions, setTreatmentOptions] = useState([])
	const [cuttingOptions, setCuttingOptions] = useState([])
	const [gemStoneTypesIds, setGemStoneTypesIds] = useState([])
	const [tStatus, setTstatus] = useState('0')
	const [parcelTypeOptions, setParcelTypeOptions] = useState([])
	const [filterDOMValues, setFilterDOMValues] = useState({
		selectRef: null, // this.selectRef.current.state.value,
		parcelTypeRef: null, // this.selectRef.current.state.value,
		colorRef: null, // this.colorRef.current.state.value,
		shapeRef: null, // this.shapeRef.current.state.value,
		originRef: null, // this.originRef.current.state.value,
		treatmentRef: null, // this.treatmentRef.current.state.value,
		cuttingRef: null, // this.cuttingRef.current.state.value,
		weightMinRef: null, // this.weightMinRef.current.value,
		weightMaxRef: null, // this.weightMaxRef.current.value,
		priceMaxRefDisplay: null, // this.priceMaxRefDisplay.current.value,
		priceMinRefDisplay: null, // this.priceMinRefDisplay.current.value,
		dimensionMinRefL: null, // this.dimensionMinRefL.current.value,
		dimensionMinRefW: null, // this.dimensionMinRefW.current.value,
		dimensionMinRefH: null, // this.dimensionMinRefH.current.value,
		dimensionMaxRefL: null, // this.dimensionMaxRefL.current.value,
		dimensionMaxRefW: null, // this.dimensionMaxRefW.current.value,
		dimensionMaxRefH: null, // this.dimensionMaxRefH.current.value,
		priceMinRef: 0, // this.priceMinRef.value,
		priceMaxRef: 0, // this.priceMaxRef.value
	})
	// let a = JSON.parse(localStorage.getItem('refValues'))
	// let b = JSON.parse(localStorage.getItem('filters'))

	const data = useSelector(state => state.productdata.stonesData)

	const cart = useSelector(state => state.cartdata.cart)
	const reduxFilter = useSelector(state => state.filterReducer.filter)
	const reduxFilterDOMValues = useSelector(
		state => state.filterReducer.filterDOMValues
	)
	const loading = useSelector(state => state.globaldata.loading)
	const dispatch = useDispatch()

	const products = useSelector(state => state.productdata.products)

	const selectRef = React.createRef()
	const parcelTypeRef = React.createRef()
	const colorRef = React.createRef()
	const shapeRef = React.createRef()
	const originRef = React.createRef()
	const treatmentRef = React.createRef()
	const cuttingRef = React.createRef()
	const weightMinRef = React.createRef()
	const weightMaxRef = React.createRef()
	const priceMaxRefDisplay = React.createRef()
	const priceMinRefDisplay = React.createRef()
	const dimensionMinRefL = React.createRef()
	const dimensionMinRefW = React.createRef()
	const dimensionMinRefH = React.createRef()
	const dimensionMaxRefL = React.createRef()
	const dimensionMaxRefW = React.createRef()
	const dimensionMaxRefH = React.createRef()

	let current_page = 0
	let last_page = 0
	let per_page = 0
	let total = 0

	if (data) {
		current_page = data.current_page
		// console.log(current_page);
		per_page = data.per_page
		last_page = data.last_page
		total = data.total
	}

	const handleFilterChangeSelect = (e, action) => {
		if (action.name === 'stone_type_id' && action.option !== undefined) {
			// console.log(action, 'actions')

			const data = {
				user_id: localStorage.getItem('user_id'),
				stone_type_id: action.option.value,
			}
			// getSubFilters(data)

			let tempArr = []

			e.forEach(singleId => {
				tempArr.push(singleId.value)
			})

			setFilters({
				...filters,
				[action.name]: tempArr,
			})
		}

		if (action.name === 'parcel_type_id') {
			setFilters({
				...filters,
				[action.name]: e === null ? null : e.value,
			})
		} else {
			let tempArr = []

			e.forEach(singleId => {
				tempArr.push(singleId.value)
			})

			setFilters({
				...filters,
				[action.name]: tempArr,
			})
		}
	}

	const handleFilterChange = e => {
		// console.log(e, 'target data')

		if (
			e.target.name === 'weight_range_from' ||
			e.target.name === 'weight_range_to'
		)
			setFilters({
				...filters,
				[e.target.name]: Number(e.target.value),
			})
		else
			setFilters({
				...filters,
				[e.target.name]: e.target.value,
			})
	}

	const getFilters = async () => {
		dispatch(setloadingtrue())
		const data = {
			user_id: localStorage.getItem('user_id'),
			user_type: localStorage.getItem('user_type'),
		}

		await ContentMain.gemtypes(data).then(res => {
			if (res) {
				//add state setter here for parcel types
				setParcelTypes(res.data.parcel_types)
				setGemstoneTypes(res.data.stone_types)
				setGemStoneCuts(res.data.stone_cuts)
				setStone_type_color(res.data.colors)
				setStone_type_origin(res.data.origins)
				setStone_type_shape(res.data.shapes)
				setStone_type_treatment(res.data.treatments)
				// setLoading(false)
				dispatch(setloadingfalse())

				let objArr = res.data.stone_types.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setAllOptions(objArr || [])

				let objArr1 = res.data.stone_cuts.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setCuttingOptions(objArr1 || [])

				let objArr2 = res.data.colors.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setColorOptions(objArr2 || [])

				let objArr3 = res.data.origins.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setOriginOptions(objArr3 || [])

				let objArr4 = res.data.shapes.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setShapeOptions(objArr4 || [])

				let objArr5 = res.data.treatments.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setTreatmentOptions(objArr5 || [])

				let objArr6 = res.data.parcel_types.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setParcelTypeOptions(objArr6 || [])

				let objArr7 = res.data.diamond_types.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setDiamondTypeOptions(objArr7 || [])
			}
		})
	}

	const getSubFilters = async data => {
		dispatch(setloadingfalse())

		try {
			const res = await ContentMain.completedContent(data)
			if (res) {
				setStone_type_color(res.data.stone_type_color)
				setStone_type_origin(res.data.stone_type_origin)
				setStone_type_shape(res.data.stone_type_shape)
				setStone_type_treatment(res.data.stone_type_treatment)

				dispatch(setloadingfalse())

				let objArr = res.data.stone_type_color.map(i => ({
					label: i.color_name,
					value: i.id,
				}))
				setColorOptions(objArr || [])

				let objArr1 = res.data.stone_type_origin.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setOriginOptions(objArr1 || [])

				let objArr2 = res.data.stone_type_shape.map(i => ({
					label: i.name,
					value: i.id,
				}))
				setShapeOptions(objArr2 || [])

				let objArr3 = res.data.stone_type_treatment.map(i => ({
					label: i.treatment_name,
					value: i.id,
				}))
				setTreatmentOptions(objArr3 || [])
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		console.log(minPrice)
	}, [minPrice])

	const submitFilters = () => {
		setFilterchange(true)

		dispatch(applyFilter(filters))
		dispatch(saveFilterDOMValues(filterDOMValues))
		dispatch(fetchallproducts(filters))
		toast.info('Filters applied', {
			className: 'info-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	const clearFilters = () => {
		selectRef.current.select.clearValue()
		parcelTypeRef.current.select.clearValue()
		colorRef.current.select.clearValue()
		shapeRef.current.select.clearValue()
		originRef.current.select.clearValue()
		treatmentRef.current.select.clearValue()
		cuttingRef.current.select.clearValue()

		Array.from(document.querySelectorAll('input')).forEach(
			input => (input.value = '')
		)
		Array.from(document.querySelectorAll('select')).forEach(
			select => (select.value = '')
		)

		let obj = {
			size_end_to: 0,
			shape_id: null,
			color_id: null,
			origin_id: null,
			size_start_to: 0,
			size_end_from: 0,
			price_range_to: 0,
			treatment_id: null,
			size_start_from: 0,
			stone_cut_id: null,
			price_range_from: 0,
			stone_type_id: null,
			parcel_type_id: null,
			stone_width_to: null,
			stone_length_to: null,
			stone_height_to: null,
			weight_range_to: null,
			reference_number: null,
			stone_width_from: null,
			stone_length_from: null,
			stone_height_from: null,
			weight_range_from: null,
		}
		let filterDOMValues = {
			parcelTypeRef: null, // this.selectRef.current.state.value,
			selectRef: null, // this.selectRef.current.state.value,
			colorRef: null, // this.colorRef.current.state.value,
			shapeRef: null, // this.shapeRef.current.state.value,
			originRef: null, // this.originRef.current.state.value,
			treatmentRef: null, // this.treatmentRef.current.state.value,
			cuttingRef: null, // this.cuttingRef.current.state.value,
			weightMinRef: null, // this.weightMinRef.current.value,
			weightMaxRef: null, // this.weightMaxRef.current.value,
			priceMaxRefDisplay: null, // this.priceMaxRefDisplay.current.value,
			priceMinRefDisplay: null, // this.priceMinRefDisplay.current.value,
			dimensionMinRefL: null, // this.dimensionMinRefL.current.value,
			dimensionMinRefW: null, // this.dimensionMinRefW.current.value,
			dimensionMinRefH: null, // this.dimensionMinRefH.current.value,
			dimensionMaxRefL: null, // this.dimensionMaxRefL.current.value,
			dimensionMaxRefW: null, // this.dimensionMaxRefW.current.value,
			dimensionMaxRefH: null, // this.dimensionMaxRefH.current.value,
			priceMinRef: null, // this.priceMinRef.value,
			priceMaxRef: null, // this.priceMaxRef.value
		}

		localStorage.removeItem('filters')
		localStorage.removeItem('refValues')
		colorRef.current.value = null
		shapeRef.current.value = null
		selectRef.current.value = null
		originRef.current.value = null
		cuttingRef.current.value = null
		treatmentRef.current.value = null
		parcelTypeRef.current.value = null

		setFilters(obj)
		setFilterDOMValues(filterDOMValues)

		dispatch(clearFilter())
		dispatch(clearFilterDOMValues())
		dispatch(fetchallproducts(obj))

		toast.info(' Filters cleared ', {
			className: 'info-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	const Ascsorting = () => {
		dispatch(sortProductsByAscPrice(products))

		toast('Products Sorted !!!', {
			className: 'warning-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	const Dscsorting = () => {
		dispatch(sortProductsByDescPrice(products))

		toast('Products Sorted !!!', {
			className: 'warning-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	const Ascweight = () => {
		dispatch(sortProductsByAscWeight(products))

		toast('Products Sorted !!!', {
			className: 'warning-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	const Dscweight = () => {
		dispatch(sortProductsByDescWeight(products))

		toast('Products Sorted !!!', {
			className: 'warning-toast',
			draggable: false,
			position: toast.POSITION.TOP_RIGHT,
		})
	}

	useEffect(() => {
		setMaxPrice(
			Math.max(
				...products.map(product =>
					parseFloat(product.custome_price_total.toString().replace(/,/g, ''))
				)
			)
		)
		setMinPrice(
			Math.min(
				...products.map(product =>
					parseFloat(product.custome_price_total.toString().replace(/,/g, ''))
				)
			)
		)
	}, [products])

	useEffect(() => {
		if (JSON.parse(localStorage.getItem('filters')) != null) {
			if (Object.keys(JSON.parse(localStorage.getItem('filters'))).length > 0) {
				setFilters(JSON.parse(localStorage.getItem('filters')))
				dispatch(applyFilter(JSON.parse(localStorage.getItem('filters'))))
			}
			if (
				Object.keys(JSON.parse(localStorage.getItem('refValues'))).length > 0
			) {
				setFilterDOMValues(JSON.parse(localStorage.getItem('refValues')))
				dispatch(
					saveFilterDOMValues(JSON.parse(localStorage.getItem('refValues')))
				)
			}
		}
	}, [])

	useEffect(() => {
		getFilters()
		let stateOfFilters

		if (localStorage.getItem('filters')) {
			stateOfFilters = JSON.parse(localStorage.getItem('filters'))

			setFilterchange(true)
			if (localStorage.getItem('refValues')) {
				let prevFilterVals = JSON.parse(localStorage.getItem('refValues'))

				setFilterDOMValues(prevFilterVals)
			}
		} else {
			stateOfFilters = filters
		}
		if (selectRef.current.state.value) {
			let subFilterParam = []
			let subFilterStuff = selectRef.current.state.value
			subFilterStuff.forEach(e => subFilterParam.push(e.value))

			const data = {
				user_id: localStorage.getItem('user_id'),
				stone_type_id: subFilterStuff,
			}

			// getSubFilters(data)
			// let a       = JSON.parse(localStorage.getItem("refValues"))
		}

		// dispatch(applyFilter(JSON.parse(localStorage.getItem('filters'))))

		// dispatch(saveFilterDOMValues(JSON.parse(localStorage.getItem('refValues'))))

		dispatch(fetchallproducts(stateOfFilters))
		if (stateOfFilters.stone_type_id && stateOfFilters.stone_type_id.length) {
			const data = {
				user_id: localStorage.getItem('user_id'),
				stone_type_id: stateOfFilters.stone_type_id,
			}
			// getSubFilters(data)
		}

		// this.getSubFilters()
	}, [])

	useEffect(() => {
		setTstatus(localStorage.getItem('template_status'))
		// if (localStorage.getItem('refValues')) {
		// 	let a = JSON.parse(localStorage.getItem('refValues'))
		// }
		if (selectRef.current.state.value) {
		}

		const priceMinRef = filterDOMValues.priceMinRef
		const priceMaxRef = filterDOMValues.priceMaxRef

		const localcart = JSON.parse(
			localStorage.getItem('cart') === null
				? localStorage.getItem('dataCart')
				: localStorage.getItem('cart')
		)

		if (localcart !== null) {
			if (localcart.length !== 0 && cart.length === 0) {
				for (let i = 0; i < localcart.length; i++) {
					dispatch(add_to_cart(localcart[i]))
				}
			}
		}
	}, [])

	useEffect(() => {
		return () => {
			if (filters_change) {
				localStorage.setItem('filters', JSON.stringify(filters))
				dispatch(applyFilter(filters))

				let refValues = {}

				refValues.parcelTypeRef = parcelTypeRef.current.state.value
				refValues.selectRef = selectRef.current.state.value
				refValues.colorRef = colorRef.current.state.value
				refValues.shapeRef = shapeRef.current.state.value
				refValues.originRef = originRef.current.state.value
				refValues.treatmentRef = treatmentRef.current.state.value
				refValues.cuttingRef = cuttingRef.current.state.value
				refValues.weightMinRef = weightMinRef.current.value
				refValues.weightMaxRef = weightMaxRef.current.value

				refValues.priceMinRef = priceMaxRefDisplay.current.value
				refValues.priceMaxRef = priceMinRefDisplay.current.value
				refValues.priceMaxRefDisplay = priceMaxRefDisplay.current.value
				refValues.priceMinRefDisplay = priceMinRefDisplay.current.value

				refValues.dimensionMinRefL = dimensionMinRefL.current.value
				refValues.dimensionMinRefW = dimensionMinRefW.current.value
				refValues.dimensionMinRefH = dimensionMinRefH.current.value
				refValues.dimensionMaxRefL = dimensionMaxRefL.current.value
				refValues.dimensionMaxRefW = dimensionMaxRefW.current.value
				refValues.dimensionMaxRefH = dimensionMaxRefH.current.value

				localStorage.setItem('refValues', JSON.stringify(refValues))
				dispatch(saveFilterDOMValues(refValues))
			}
		}
	}, [])

	if (products !== null && products !== undefined) {
		listItems = products.map(item => (
			<Link
				to={{ pathname: '/detail/' + item.id }}
				className='col-lg-3 col-md-4 col-sm-6 col-12 d-flex mb-md-5 mb-4 pb-md-0 pb-2 box-anchor'
				key={item.id}
			>
				<div className='w-100' id='row'>
					<div className='custom-card w-100 h-100 p-3 d-flex flex-column'>
						<div className='align-items-center custom-card_img d-flex justify-content-center w-100'>
							<img
								src={item.image}
								alt=''
								className='d-block img-fluid mx-auto'
							/>
						</div>
						<div className='custom-card_header mt-3'>
							<h2>
								Ref # :{' '}
								{item.internal_reference_number !== ''
									? item.internal_reference_number
									: item.system_reference_number}
							</h2>
							<p style={{ padding: '0px 20px' }}>
								{item.stone_types != null
									? `${item.stone_types}${
											item.stone_types === '' || item.stone_types == null
												? ''
												: ', '
									  }`
									: ''}
								{item.weight != null ? `${item.weight} ` : ''}
								{item.weight_type != null
									? `${item.weight_type}${
											!item.weight_type && !item.origin
												? ''
												: item.weight_type && !item.origin
												? ''
												: !item.weight_type && item.origin
												? ''
												: ', '
									  } `
									: ''}
								{item.origin != null ? item.origin : ''}
							</p>
							<p className='pricee'>
								Total Price :
								<NumberFormat
									value={item.custome_price_total}
									displayType={'text'}
									thousandSeparator={true}
									prefix={item.custome_price_currency}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</p>
							<p className='pricee'>
								{item.treatment !== '' ? item.treatment : 'Unknown'}
							</p>
						</div>
						<Link to='/products' replace style={{ textDecoration: 'none' }}>
							{' '}
							<div className='custom-card_footer mt-auto pt-2'>
								<button
									className='custom-btn cart-btn'
									onClick={() => {
										const check = cart.every(cartItem => {
											return cartItem.id !== item.id
										})
										if (check) {
											dispatch(add_to_cart(item))
											dispatch(incrementData())

											let arr = []
											arr = JSON.parse(localStorage.getItem('cart'))
											arr.push(item)
											localStorage.setItem('cart', JSON.stringify(arr))
											localStorage.setItem('dataCart', JSON.stringify(arr))

											toast('Product added to cart Successfully', {
												className: 'success-toast',
												draggable: false,
												position: toast.POSITION.TOP_RIGHT,
											})
										} else {
											toast.error('Product already added to cart !!!', {
												className: 'danger-toast',
												draggable: false,
												position: toast.POSITION.TOP_RIGHT,
											})
										}
									}}
								>
									{cart.every(cartItem => {
										return cartItem.id !== item.id
									})
										? 'Add To Cart'
										: 'Added to Cart'}
								</button>
							</div>
						</Link>
					</div>
				</div>
			</Link>
		))
	}

	return (
		<>
			{loading && <Loader />}

			<div className='main_content mainWrapper container '>
				<div className='w-100'>
					{loading && <Loader />}

					<Form
						className={`filters-form  ${tStatus === '1' ? 'my-5' : 'my-1'}`}
					>
						<Form.Group as={Row} className='mb-0'>
							{/* Gemstone Type */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Parcel Type :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Select
											// isMulti
											ref={parcelTypeRef}
											name='parcel_type_id'
											options={parcelTypeOptions}
											onChange={(e, action) => {
												console.log(e, 'e values')
												console.log(action, 'action values')
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)

												setFilterDOMValues({
													...filterDOMValues,
													parcelTypeRef: e,
												})
											}}
											placeholder='--Select one of the following--'
											value={filterDOMValues.parcelTypeRef}
										/>
									</Col>
								</Row>
							</Col>
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										{filters.parcel_type_id === 7 ||
										filters.parcel_type_id === 8
											? 'Diamond'
											: 'Gemstone'}{' '}
										Type :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={
												filters.parcel_type_id === 7 ||
												filters.parcel_type_id === 8
													? diamondTypeOptions
													: allOptions
											}
											name={
												filters.parcel_type_id === 7 ||
												filters.parcel_type_id === 8
													? 'diamond_type_id'
													: 'stone_type_id'
											}
											ref={selectRef}
											onChange={(e, action) => {
												// console.log(e, 'e values')
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)

												setFilterDOMValues({
													...filterDOMValues,
													selectRef: e,
												})
											}}
											placeholder='--Select one of the following--'
											value={filterDOMValues.selectRef}
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Color */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Color :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={colorOptions}
											name='color_id'
											ref={colorRef}
											value={filterDOMValues.colorRef}
											onChange={(e, action) => {
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)

												setFilterDOMValues({
													...filterDOMValues,
													colorRef: e,
												})
											}}
											placeholder='--Select one of the following--'
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Shape */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Shape :
									</Form.Label>
									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={shapeOptions}
											name='shape_id'
											ref={shapeRef}
											// onChange={(e,action) => this.onChangeS(e,action)}
											onChange={(e, action) => {
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)

												setFilterDOMValues({
													...filterDOMValues,
													shapeRef: e,
												})
											}}
											placeholder='--Select one of the following--'
											value={filterDOMValues.shapeRef}
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Origin */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Origin :
									</Form.Label>
									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={originOptions}
											name='origin_id'
											ref={originRef}
											onChange={(e, action) => {
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)

												setFilterDOMValues({
													...filterDOMValues,
													originRef: e,
												})
											}}
											placeholder='--Select one of the following--'
											value={filterDOMValues.originRef}
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Treatment */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Treatment :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={treatmentOptions}
											name='treatment_id'
											ref={treatmentRef}
											// onChange={(e,action) => this.onChangeS(e,action)}
											onChange={(e, action) => {
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action)
												setFilterDOMValues({
													...filterDOMValues,
													treatmentRef: e,
												})
											}}
											placeholder='--Select one of the following--'
											value={filterDOMValues.treatmentRef}
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Cutting Type */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Cutting Type :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Select
											isMulti
											isDisabled={filters.parcel_type_id === null}
											options={cuttingOptions}
											name='stone_cut_id'
											ref={cuttingRef}
											value={filterDOMValues.cuttingRef}
											// onChange={(e,action) => this.onChangeS(e,action)}
											onChange={(e, action) => {
												handleFilterChangeSelect(e, action)
												// onChangeS(e,action);
												setFilterDOMValues({
													...filterDOMValues,
													cuttingRef: e,
												})
											}}
											placeholder='--Select one of the following--'
										/>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Weight Range */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Weight Range (CT) :
									</Form.Label>

									<Col sm='12' lg='8'>
										<Row>
											<Col xs='6'>
												<Form.Control
													type='number'
													name='weight_range_from'
													placeholder='From'
													ref={weightMinRef}
													size='sm'
													value={
														Number(filterDOMValues.weightMinRef) !== 0
															? Number(filterDOMValues.weightMinRef)
															: null
													}
													onChange={e => {
														handleFilterChange(e)

														setFilterDOMValues({
															...filterDOMValues,
															weightMinRef: Number(e.target.value),
														})
													}}
												/>
											</Col>

											<Col xs='6'>
												<Form.Control
													type='number'
													name='weight_range_to'
													placeholder='To'
													ref={weightMaxRef}
													size='sm'
													value={
														Number(filterDOMValues.weightMaxRef) !== 0
															? Number(filterDOMValues.weightMaxRef)
															: null
													}
													onChange={e => {
														handleFilterChange(e)
														setFilterDOMValues({
															...filterDOMValues,
															weightMaxRef: Number(e.target.value),
														})
													}}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							{/* Gemstone Price Range */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										sm='12'
										lg='4'
										className='d-flex align-items-center mb-1 mb-lg-0'
									>
										Price Range (USD) :
									</Form.Label>

									{products.length === 0 ? (
										<></>
									) : (
										<Col xs='12' sm='12' lg='8'>
											<Row>
												<Col xs='6'>
													<RangeSlider
														id='price_range_from'
														value={
															filterDOMValues.priceMinRef
																? Number(filterDOMValues.priceMinRef)
																: filters.price_range_from != null
																? Number(filters.price_range_from)
																: minPrice
														}
														onChange={e => {
															// handleFilterChange(e)
															setFilters({
																...filters,
																price_range_from: Number(e.target.value),
															})

															setFilterDOMValues({
																...filterDOMValues,
																priceMinRef: Number(e.target.value),
																priceMinRefDisplay: Number(e.target.value),
															})
														}}
														min={minPrice}
														max={Number(filters.price_range_to)}
													/>
												</Col>

												<Col xs='6'>
													<RangeSlider
														id='price_range_to'
														controlId
														value={
															filterDOMValues.priceMaxRef === 0
																? Number(filterDOMValues.priceMaxRef)
																: filters.price_range_to !== 0
																? Number(filters.price_range_to)
																: maxPrice
														}
														onChange={e => {
															setFilters({
																...filters,
																price_range_to: Number(e.target.value),
															})
															setFilterDOMValues({
																...filterDOMValues,
																priceMaxRef: Number(e.target.value),
																priceMaxRefDisplay: Number(e.target.value),
															})
														}}
														min={Number(filters.price_range_from)}
														max={maxPrice}
														// ref={this.priceMaxRef}
													/>
												</Col>
												<Col xs='6'>
													<Form.Control
														value={
															filters.price_range_from != null
																? Number(filters.price_range_from)
																: minPrice
														}
														onChange={e => {
															// handleFilterChange(e)
															setFilters({
																...filters,
																price_range_from: Number(e.target.value),
															})

															setFilterDOMValues({
																...filterDOMValues,
																priceMinRef: Number(e.target.value),
																priceMinRefDisplay: Number(e.target.value),
															})
														}}
														size='sm'
														// readOnly
														ref={priceMaxRefDisplay}
													/>
												</Col>

												<Col xs='6'>
													<Form.Control
														value={
															filters.price_range_to !== 0
																? filters.price_range_to
																: maxPrice
														}
														onChange={e => {
															setFilters({
																...filters,
																price_range_to: Number(e.target.value),
															})
															setFilterDOMValues({
																...filterDOMValues,
																priceMaxRef: Number(e.target.value),
																priceMaxRefDisplay: Number(e.target.value),
															})
														}}
														size='sm'
														// readOnly
														ref={priceMinRefDisplay}
													/>
												</Col>
											</Row>
										</Col>
									)}
								</Row>
							</Col>

							{/* Gemstone Dimension Range */}
							<Col sm='6' className='mb-3 form-field-col'>
								<Row>
									<Form.Label
										column
										lg='4'
										sm='12'
										className='d-flex align-items-top mb-1 mb-lg-0'
									>
										Dimensions Range :
									</Form.Label>
									{/* Gemstone Dimension From */}
									<Col sm='12' md='12' lg='8' className='mb-3 form-field-col'>
										<Col sm='12' className='mb-3 form-field-col'>
											<Row>
												<Form.Label
													column
													sm='12'
													lg='2'
													className='d-flex align-items-center mb-1 mb-lg-0 px-0'
												>
													From :
												</Form.Label>

												<Col sm='12' lg='10' className='px-0'>
													<div className='align-items-center d-flex'>
														<div className='pr-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_length_from'
																placeholder='Length'
																value={
																	Number(filterDOMValues.dimensionMinRefL) !== 0
																		? Number(filterDOMValues.dimensionMinRefL)
																		: null
																}
																// onChange={(e) => this.handleFilterChange(e)}
																onChange={e => {
																	handleFilterChange(e)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMinRefL: Number(e.target.value),
																	})
																}}
																ref={dimensionMinRefL}
															/>
														</div>

														<div>
															<span>x</span>
														</div>

														<div className='px-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_width_from'
																placeholder='Width'
																// onChange={(e) => this.handleFilterChange(e)}
																value={
																	Number(filterDOMValues.dimensionMinRefW) !== 0
																		? Number(filterDOMValues.dimensionMinRefW)
																		: null
																}
																onChange={e => {
																	handleFilterChange(e)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMinRefW: Number(e.target.value),
																	})
																}}
																ref={dimensionMinRefW}
															/>
														</div>

														<div>
															<span>x</span>
														</div>

														<div className='pl-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_height_from'
																placeholder='Height'
																// onChange={(e) => this.handleFilterChange(e)}
																value={
																	// Number(this.state.filterDOMValues.dimensionMinRefH)
																	Number(filterDOMValues.dimensionMinRefH) !== 0
																		? Number(filterDOMValues.dimensionMinRefH)
																		: null
																}
																onChange={e => {
																	handleFilterChange(e)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMinRefH: Number(e.target.value),
																	})
																}}
																ref={dimensionMinRefH}
															/>
														</div>
													</div>
												</Col>
											</Row>
										</Col>
										{/* Gemstone Dimension From */}
										<Col sm='12' className='mb-3 form-field-col'>
											<Row>
												<Form.Label
													column
													sm='12'
													lg='2'
													className='d-flex align-items-center mb-1 mb-lg-0 px-0'
												>
													To :
												</Form.Label>

												<Col sm='12' lg='10' className='px-0'>
													<div className='align-items-center d-flex'>
														<div className='pr-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_length_to'
																placeholder='Length'
																value={
																	// Number(this.state.filterDOMValues.dimensionMaxRefL)
																	Number(filterDOMValues.dimensionMaxRefL) !== 0
																		? Number(filterDOMValues.dimensionMaxRefL)
																		: null
																}
																// onChange={(e) => this.handleFilterChange(e)}
																onChange={e => {
																	handleFilterChange(e)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMaxRefL: Number(e.target.value),
																	})
																}}
																ref={dimensionMaxRefL}
															/>
														</div>

														<div>
															<span>x</span>
														</div>

														<div className='px-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_width_to'
																placeholder='Width'
																// onChange={(e) => this.handleFilterChange(e)}
																value={
																	// Number(this.state.filterDOMValues.dimensionMaxRefW)
																	Number(filterDOMValues.dimensionMaxRefW) !== 0
																		? Number(filterDOMValues.dimensionMaxRefW)
																		: null
																}
																onChange={e => {
																	handleFilterChange(e)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMaxRefW: Number(e.target.value),
																	})
																}}
																ref={dimensionMaxRefW}
															/>
														</div>

														<div>
															<span>x</span>
														</div>

														<div className='pl-2'>
															<Form.Control
																size='sm'
																type='number'
																name='stone_height_to'
																placeholder='Height'
																// onChange={(e) => this.handleFilterChange(e)}
																value={
																	// Number(this.state.filterDOMValues.dimensionMaxRefH)
																	Number(filterDOMValues.dimensionMaxRefH) !== 0
																		? Number(filterDOMValues.dimensionMaxRefH)
																		: null
																}
																onChange={e => {
																	handleFilterChange(e)
																	// console.log(e.target.value)
																	setFilterDOMValues({
																		...filterDOMValues,
																		dimensionMaxRefH: Number(e.target.value),
																	})
																}}
																ref={dimensionMaxRefH}
															/>
														</div>
													</div>
												</Col>
											</Row>
										</Col>
									</Col>
								</Row>
							</Col>
						</Form.Group>
					</Form>

					{/* Clear & Apply Filter */}
					<Row>
						<Col sm={{ span: 12 }}>
							<div className='pull-right'>
								<button className='clear-filter' onClick={clearFilters}>
									Clear Filter
								</button>
								<button
									className='checkout-btn text-uppercase btn btn-primary cart-btn ml-4'
									onClick={() => submitFilters()}
								>
									Apply Filter
								</button>
							</div>
						</Col>
					</Row>

					<hr />
					<Row className='search-results'>
						<Col className='col-md-6 col-12 mb-md-0 mb-2'>
							{filters_change === true && <p>Search Results ({total})</p>}
						</Col>
						<Col className='col-12 col-md-6 d-flex justify-content-end align-items-center'>
							{/* Sort : <i className="fa fa-angle-down mr-2"></i>  */}

							<div className='d-inline-block dropdown mr-4'>
								<select
									className='form-control'
									onChange={e =>
										e.target.value === 'asc' ? Ascsorting() : Dscsorting()
									}
								>
									<option selected>Sort by Price</option>
									<option value='asc'>Low to High</option>
									<option value='desc'>High to Low</option>
								</select>
								{/* <button
									className='btn btn-secondary dropdown-toggle'
									type='button'
									id='dropdownMenuButton'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									Sort by Price
								</button>
								<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
									<a
										className='dropdown-item'
										href='#low'
										onClick={() => Ascsorting()}
									>
										Low to High
									</a>
									<a
										className='dropdown-item'
										href='#low'
										onClick={() => Dscsorting()}
									>
										High to Low
									</a>
								</div> */}
							</div>
							<div className='d-inline-block dropdown mr-4'>
								<select className='form-control'>
									<option selected>Sort by Weight</option>
									<option onClick={() => Ascweight()}>Low to High</option>
									<option onClick={() => Dscweight()}>High to Low</option>
								</select>
								{/* <button
									className='btn btn-secondary dropdown-toggle'
									type='button'
									id='dropdownMenuButton'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
								>
									Sort by Weight
								</button>
								<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
									<a
										className='dropdown-item'
										href='#low'
										onClick={() => Ascweight()}
									>
										Low to High
									</a>
									<a
										className='dropdown-item'
										href='#low'
										onClick={() => Dscweight()}
									>
										High to Low
									</a>
								</div> */}
							</div>

							<p className='mb-0'>
								{current_page === last_page && (
									<i
										className='fa fa-caret-left mr-2'
										onClick={() =>
											dispatch(fetchallproducts(filters, --current_page))
										}
									></i>
								)}
								{current_page !== last_page && (
									<i className='fa fa-caret-left mr-2 disabled-color'></i>
								)}
								{current_page}
								{current_page !== last_page && (
									<i
										className='fa fa-caret-right ml-2'
										onClick={() =>
											dispatch(fetchallproducts(filters, ++current_page))
										}
									></i>
								)}
								{current_page === last_page && (
									<i className='fa fa-caret-right ml-2 disabled-color'></i>
								)}
							</p>
						</Col>
					</Row>
				</div>
				<div className='w-100'>
					<div className='mt-3 row'>{listItems}</div>
				</div>

				<div className='d-flex justify-content-center w-100'>
					<div>
						<Pagination
							activePage={current_page}
							totalItemsCount={total}
							itemsCountPerPage={per_page}
							onChange={pageNumber =>
								dispatch(fetchallproducts(filters, pageNumber))
							}
							itemClass='page-item'
							linkClass='page-link'
							pageRangeDisplayed={10}
							hideFirstLastPages={true}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default MainContent
