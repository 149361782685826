import React, { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'
import { CartService } from '../../services/cart-service'
import { useSelector } from 'react-redux'
import {
	add_to_cart,
	clearCart,
	remove_from_cart,
} from '../../store/actions/cart.action.js'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Cart = props => {
	const [cartList, setCartList] = useState([])
	const [loading, setLoading] = useState(false)
	const [vendor_id] = useState(window.config.vendor_id)

	const cart = useSelector(state => state.cartdata.cart)

	const product = useSelector(state => state.productdata.products)

	const dispatch = useDispatch()

	useEffect(() => {
		// localStorage.removeItem('filters')
		// localStorage.removeItem('refValues')

		const localcart = JSON.parse(
			localStorage.getItem('cart') === null
				? localStorage.getItem('dataCart')
				: localStorage.getItem('cart')
		)

		if (localcart !== null) {
			if (localcart.length !== 0 && cart.length === 0) {
				for (let i = 0; i < localcart.length; i++) {
					dispatch(add_to_cart(localcart[i]))
				}
			}
		}
	}, [])

	useEffect(() => {
		if (cart !== null) localStorage.setItem('cart', JSON.stringify(cart))
		localStorage.setItem('dataCart', JSON.stringify(cart))
	}, [cart])

	const saveStoneList = async e => {
		e.preventDefault()
		setLoading(true)
		let isVendor = localStorage.getItem('user_type') === 'vendor'
		// let url         = `${window.config.api_url}/save-stone-list`
		let data = {
			stone_ids: cartList,
			user_id: vendor_id,
			customer_id: isVendor ? null : localStorage.getItem('user_id'),
		}

		await CartService.completedCart(data).then(res => {
			if (res.data.success) {
				let msg = 'The vendor will be in touch shortly about your order.'
				// let msg         = isVendor ? "" : "The vendor will be in touch shortly about your order."
				// let confirm     = window.confirm(`Your order has been placed successfully and ${data.id} has been created. \n${msg}`)
				let confirm = toast(
					`Your order has been placed successfully and ${res.data.id} has been created. \n${msg}`,
					{
						className: 'success-toast',
						draggable: false,
						position: toast.POSITION.TOP_RIGHT,
					}
				)
				// window.confirm(`Your order has been placed successfully and ${data.id} has been created. \n${msg}`)
				let timeOut = confirm ? 1500 : 1000

				localStorage.setItem('cart', JSON.stringify([]))
				localStorage.setItem('dataCart', JSON.stringify([]))

				dispatch(clearCart)
				setTimeout(() => {
					window.location = '/products'
				}, timeOut)
			} else {
				setLoading(false)
				toast('Something went wrong. Please try again later!', {
					className: 'error-toast',
					draggable: false,
					position: toast.POSITION.TOP_RIGHT,
				})
			}
		})
	}

	if (cart.length > 0 && cart !== undefined) {
		let sum = cart.reduce(
			(prev, current) => prev + +current.custome_price_total,
			0
		)

		for (let i = 0; i < cart.length; i++) {
			cartList.push(cart[i].id)
		}

		return (
			<div
				className='main_content mainWrapper'
				style={{ alignItems: 'normal' }}
			>
				<section className='w-100 container'>
					<h4 className='mb-4 h-tag mt-5'>
						{' '}
						Purchase Orders ({cart.length} items)
					</h4>
					<Row className=''>
						<Col md={{ span: 12 }} lg={{ span: 8 }} sm={{ span: 12 }}>
							<div className='single-product w-100 mb-4'>
								{cart.map(item => (
									<div key={item.id}>
										<div className='row'>
											<div className='col-lg-3 col-md-3 col-sm-3 col-3'>
												<img
													src={item.image}
													alt={item.stone_types}
													className='img-fluid'
													width='156px'
												/>
											</div>
											<div className='col-lg-9 col-md-9 col-sm-9 col-9'>
												<h4>
													Ref # :{' '}
													{item.internal_reference_number !== ''
														? item.internal_reference_number
														: item.system_reference_number}
												</h4>
												<p className='mb-1 mt-4'>
													{item.stone_types != null
														? `${item.stone_types}${
																item.stone_types === '' ||
																item.stone_types == null
																	? ''
																	: ', '
														  }`
														: ''}
													{item.weight != null ? `${item.weight} ` : ''}
													{item.weight_type != null
														? `${item.weight_type}${
																!item.weight_type && !item.origin
																	? ''
																	: item.weight_type && !item.origin
																	? ''
																	: !item.weight_type && item.origin
																	? ''
																	: ', '
														  } `
														: ''}
													{item.origin != null ? item.origin : ''}
												</p>
												<Row>
													<Col md={{ span: 8 }}>
														<p
															className='mb-1 mt-4 text-uppercase remove-item'
															onClick={() => {
																dispatch(remove_from_cart(item.id))
																// localStorage.setItem('cart',JSON.stringify(cart))
															}}
														>
															<i
																className='fa fa-trash'
																title='Remove Item'
															></i>{' '}
															Remove Item
														</p>
													</Col>
													<Col md={{ span: 4 }}>
														<p className='mb-1 mt-4 font-weight-bold text-center'>
															<NumberFormat
																value={item.custome_price_total}
																displayType={'text'}
																thousandSeparator={true}
																prefix={item.custome_price_currency}
																decimalScale={2}
																fixedDecimalScale={true}
															/>
														</p>
													</Col>
												</Row>
											</div>
										</div>
										<hr></hr>
									</div>
								))}
							</div>
						</Col>
						<Col md={{ span: 12 }} lg={{ span: 4 }} sm={{ span: 12 }}>
							<div className='single-product w-100 mb-4'>
								<Row>
									<Col md={{ span: 8 }}>
										<p>Amount</p>
									</Col>
									<Col md={{ span: 4 }} className='text-center'>
										<b>
											<NumberFormat
												value={sum}
												displayType={'text'}
												thousandSeparator={true}
												prefix={cart[0].custome_price_currency}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</b>
									</Col>
								</Row>

								<Row>
									<Col md={{ span: 8 }}>
										<p>Shipping</p>
									</Col>
									<Col md={{ span: 4 }} className='text-center'>
										<b>To be determind </b>
									</Col>
								</Row>

								<hr></hr>

								<Row>
									<Col md={{ span: 8 }}>
										<p>The total</p>
									</Col>
									<Col md={{ span: 4 }} className='text-center'>
										<b>
											<NumberFormat
												value={sum}
												displayType={'text'}
												thousandSeparator={true}
												prefix={cart[0].custome_price_currency}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</b>
									</Col>
								</Row>

								<Row>
									<Col md={{ span: 12 }}>
										<div className='w-100 text-center'>
											<button
												disabled={loading}
												onClick={saveStoneList}
												className='checkout-btn text-uppercase btn btn-primary cart-btn w-50'
											>
												Checkout
											</button>
										</div>
									</Col>
								</Row>
							</div>

							<Row className='send-po'>
								<Col md={{ span: 12 }}>
									<div className='w-100 text-center'>
										{/* <button className="checkout-btn text-uppercase btn btn-primary cart-btn w-50">Send Po</button> */}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</section>
			</div>
		)
	} else {
		return <Redirect to='/products' />
	}
}

export default Cart
