import React, { useState} from 'react';
import './App.css';
import MainContent from "./components/Products/MainContent";
import Cart from "./components/Products/Cart";
import { ToastContainer } from 'react-toastify';
import { AboutUs, ContactUs, Home, OurCollection } from "./components/Layout/theme_one/index";
import ProductDetail from "./pages/ProductDetail";
import Login from "./components/Auth/Login";
import AdminLogin from "./components/Auth/AdminLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import { Switch, Route } from 'react-router-dom';
import Footer from "./components/Layout/common/Footer";
import Checkout from "./components/Products/Checkout";
import Header from "./components/Layout/common/Header";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let alreadyLoggedIn = localStorage.getItem('token') ? true : false

const App=()=>{
  const [user,setuser]=useState(null)
  const [isAuth,setIsAuth]=useState(alreadyLoggedIn)
  const [theme,setTheme]=useState('noTheme')

  const  handleLogin = data => 
  {
    setuser(data.user)
    setIsAuth(true)
  }
  const setUser = user =>
  {
    setuser(user)
    setIsAuth(false)
  }
  
  return (
    <>
    {/* <DataProvider isAuth={isAuth}>  */}
    <ToastContainer autoClose={2000}/>
    <Header setUser={setUser} isAuth={isAuth} />
    {
      theme === 'one' 
      ? <Switch>
          <Route exact path='/home' render={ props => <Home {...props} isAuth={isAuth} /> } />
          <Route exact path='/about-us' render={ props => <AboutUs {...props} isAuth={isAuth}/> } />
          <Route exact path='/contact-us' render={ props => <ContactUs {...props} isAuth={isAuth} /> } />
          <Route exact path='/our-collection' render={ props => <OurCollection {...props} isAuth={isAuth} /> } />
        </Switch>
    
      : theme === 'noTheme' 
      ? <Switch>
          <Route exact path='/' render={ props => <Home {...props} isAuth={isAuth} /> } />
          <Route exact path='/about-us' render={ props => <AboutUs {...props} isAuth={isAuth} /> } />
          <Route exact path='/contact-us' render={ props => <ContactUs {...props} isAuth={isAuth} /> } />
        </Switch>
      : ""
    }
          
    <Switch>
      <Route exact path='/login' render={ props => <Login {...props} handleLogin={handleLogin} isAuth={isAuth}/> } />
      <Route exact path='/admin/login/:id' render={ props => <AdminLogin {...props} handleLogin={handleLogin} isAuth={isAuth}/> } />
      
      <ProtectedRoute exact path='/products' component={MainContent} isAuth={isAuth}/>
     
      <ProtectedRoute exact path='/detail/:id' component={ProductDetail} isAuth={isAuth}/>
      <ProtectedRoute exact path='/cart' component={Cart} isAuth={isAuth}/>
      <ProtectedRoute exact path='/checkout' component={ Checkout } isAuth={isAuth}/>
      
    </Switch>

    <Footer />
    {/* </DataProvider> */}
    </>

  )
}
export default App



